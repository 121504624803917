/*!
* @file      home.css
* @author    Dharmik Dholariya and Harpreet Singh 
* @date      02-06-2022
* @brief     This is the stylesheet page for home page for LookMeUp project.
*/

.homeContainer{
    display: flex;
}
::-webkit-scrollbar {
    display: none;
    width: 0px;
}

