.rightbar {
  flex: 3;
}

.homerightbarWrapper {

  padding: 0px 20px 0 0;
}

.SuggestionsCard
{
    margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  margin-left: 1%;
  background-color: white;
  border-radius: 10px;
-webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}


.follower{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.follower>div{
    display: flex;
    gap: 10px;
}

.followerImg
{
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    object-fit: cover;
}

.username
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.username>span:nth-of-type(1)
{
    
    font-weight:300;
}

.add-friend-button
{
  width: 4.3rem;
  height: 2rem ;
  margin-top: 10px;
  align-self:flex-start;
  align-items: center;
  align-content: center;
  border: none;
  background-color: #1877f2;
  color: white;
  border-radius: 5px;
  padding: 5px  10px;
  display: flex;
  font-size: 16px;
  font-family: 500; 
}

.add-friend-button:hover
{
    cursor: pointer;
    color: #1877f2;
    background: white;
    border: 2px solid #1877f2;

}

.no-suggestions-found
{
    width: 100%;
    text-align:center;
    /* display:block; */
    /* background-color: transparent; */
    /* border: 1px solid transparent; */
    /* margin-right: 10px; */
    margin-bottom: 20px;
    margin-top: 20px;

    /* float:center; */
    /* border-radius: 6px;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68); */
}



.no-suggestions-text
{
   font-size: 14px;
   font-weight: 600;
   margin-bottom: 20px;
}
