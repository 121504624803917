/*!
* @file      topbar.css
* @author    Dharmik Dholariya and Harpreet Singh 
* @date      02-06-2022
* @brief     This is the stylesheet page for topbar component page (navigation bar) for LookMeUp project.
*/

.topbarContainer {
    height: 80px;
    width: 100%;
    background-color: #1877f2;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .topbarLeft {
    flex: 3;
  }
  
  .logo {
    font-size: 24px;
    margin-left: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
  
  .topbarCenter {
    flex: 5;
  }
  
  .searchbar {
    width: 100%;
    height: 40px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
  }
  
  .searchIcon {
    font-size: 20px !important;
    margin-left: 15px;
    margin-right: 10px;
  }
  
  .searchInput {
    border: none;
    width: 88%;
  }
  
  .searchInput:focus {
    outline: none;
  }
  
  .topbarRight {
    
    flex: 3;
    display: flex;
    align-items: center;
    justify-content:right;
    margin-right: 10px;
    color: white;
  }

  .home_button
  {
    align-items:flex-start;
    display: flex;
    justify-content: flex-start;
    margin-right: 3px;
    align-items: center;
    cursor: pointer;
  }

 .home_text
 {
  margin-right: 10px;
  font-size: 17px;
  cursor: pointer;
 }

 .timeline_button
 {
   align-items:flex-start;
   display: flex;
   justify-content: flex-start;
   margin-right: 3px;
   align-items: center;
   cursor: pointer;
 }

.timeline_text
{
 margin-right: 10px;
 font-size: 17px;
 cursor: pointer;
}


.logout_button
{
  align-items:flex-start;
  display: flex;
  justify-content: flex-start;
  margin-right: 3px;
  align-items: center;
  cursor: pointer;
}

.logout_text
{
margin-right: 20px;
font-size: 17px;
cursor: pointer;
}

 
  
  .helloUser
  {
    margin-right: 10px;
    font-size:20px;
  }
  
  .topbarImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white;
    align-items: flex-end;
    object-fit: cover;
    cursor: pointer;
  }

  /* .logout
  {
    align-items: flex-end;
  } */