.rightbar {
  flex: 3;
  margin-left: 10px;

}

.rightbarWrapper {
  padding: 20px 20px 0 0;
}

/* .rightbarTitle
{
    font-size: 20px;
    font-family: 500;
    margin-bottom: 20px;
}

.rightbarInfo
{
    margin-bottom: 30px;
}

.rightbarInfoItem
{
    margin-bottom: 10px;
}

.rightbarInfoKey
{
    font-weight: 600;
    margin-right: 15px;
    color: #555;
}

.rightbarInfoValue
{
    font-weight: 300;
} */




.AddSearch {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 10px;

}

.Search {
  display: flex;
  border-radius: 5px;
  margin-left: 12px;
  /* margin-bottom: 5px; */
  padding: 5px;
  /* width: 100%; */
  background-color: white;
  border: 2px solid #1877f2;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.Search>input {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 15px;

}

.s-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1877f2;
  border-radius: 5px;
  padding: 2px;
  color: white;

}

.s-icon:hover {
  cursor: pointer;
}

/* Before Search  */

.rightbarFollowButton {
  margin-bottom: 1px;
  margin-left: 14px;
  border: none;
  background-color: #1877f2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 500;

}

.InfoCardRight {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-left: 1%;
  background-color: white;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.infoHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}

.infoHead>div:hover {
  cursor: pointer;

}

.info {
  margin-bottom: 7px;
}

.logout-button {
  cursor: pointer;
  width: 6rem;
  height: 2rem;
  margin-top: 10px;
  align-self: flex-start;
  align-items: center;
  border: none;
  background-color: #1877f2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  font-size: 16px;
  font-family: 500;

}




.rightbarTitle {
  font-size: 18px;
  margin-top: 15px;
  margin-left: 14px;
  font-weight: bold;
  margin-bottom: 0px;
}


.rightbarFollowings {
  /* margin-left: 1%; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  /* background-color: white;
  border-radius: 10px;
-webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68); */
}

.rightbarFollowing {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 100px;
  margin-top: 10px;
  margin-left: 12px;
  margin-right: 5px;
  /* display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer; */
  /* position: relative; */
  /* overflow: hidden; */
  /* float: center; */
}

.rightbarFollowing>img {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  object-fit: cover;
  justify-content: space-between;


}

/* .rightbarFollowingImg{
  width: 110px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px ;
} */

.rightbarFollowingName {

  display: block;
  font-size: 13px;
  font-weight: 600;
  /* display: flex; */
  /* color: white;
  align-items: center;
  
  position:absolute;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  bottom: 1px;
  text-align: center; */
  /* float: center; */
  /* background-color: black;
  opacity: 0.8; */
}


.no-friends-found {
  width: 100%;
  text-align: center;
  /* display:block; */
  /* background-color: transparent; */
  /* border: 1px solid transparent; */
  /* margin-right: 10px; */
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 15px;

  /* float:center; */
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}



.no-friends-text {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
}