/*!
* @file      share.css
* @author    Dharmik Dholariya and Harpreet Singh 
* @date      02-06-2022
* @brief     This is the stylesheet page for share component page (creating posts) for LookMeUp project.
*/

.share {
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  }
  
  .shareWrapper {
    padding: 10px;
  }
  
  .shareTop {
    display: flex;
    align-items: center;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 12px -8px rgba(0, 0, 0, 0.68);
    margin-top: 10px;
  }
  
  .shareProfileImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: 15px;
  }
  
  .shareInput {
    border: none;
    width: 80%;
    font-size: medium;
  }
  
  .shareInput:focus {
    outline: none;
  }
  
  .shareHr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .shareBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .shareOptions{
      display: flex;
      margin-left: 20px;
  }
  
  .shareOption{
      display: flex;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
  }
  
  .shareIcon{
      font-size: 18px;
      margin-right: 3px;
      margin-left: 50px;
  }
  
  .shareOptionText{
      font-size: 14px;
      font-weight: 500;
      margin-left: 5px;
  }
  
  .shareButton{
      border: none;
      width: 120px;
      padding: 7px;
      border-radius: 5px;
      background-color: #1877f2;
      font-weight: 500;
      margin-right: 20px;
      cursor: pointer;
      color: white;
  }

.shareImgContainer
{
  padding: 0 20px 10px 20px;
  position: relative;
}

.shareImg{
  width: 100%;
  object-fit: cover;
}

.shareCancelImg
{
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
  opacity: 0.5;
}